import React from "react"
import SEO from "../components/common/SEO/SEO"

export default () => (
  <div className="application">
    <SEO />
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `<html> <head> <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"> <title>Designer form</title> <style type="text/css"> html{ margin: 0; height: 100%; overflow: hidden; } iframe{ position: absolute; left:0; right:0; bottom:0; top:0; border:0; } </style> </head> <body> <iframe id="typeform-full" width="100%" height="100%" frameborder="0" src="https://creagent.typeform.com/to/XkIfa5"></iframe> <script type="text/javascript" src="https://embed.typeform.com/embed.js"></script> </body> </html>`,
      }}
    />
  </div>
)
